.leadFormModal {
  --modal-width: 100%;
  --modal-padding: 0;
  --modal-panel-width: 100%;
  --modal-panel-height: 100%;
  --modal-panel-padding: var(--space-3x) var(--space-6x);
  --modal-display: flex;
  --modal-panel-display: flex;

  @media (--screen-md) {
    --modal-margin: auto;
    --modal-panel-min-height: 552px;
    --modal-width: fit-content;
    --modal-height: fit-content;
    --modal-panel-height: fit-content;
    --modal-panel-width: 400px;
  }
}

.elfPrompts {
  flex-direction: column;
}

.body {
  height: 100%;
  width: 100%;
}

.leadFormModalPanel {
  --modal-close-button-padding: var(--space-3x) var(--space-5x);
  flex-direction: column;
  gap: var(--space-4x);
}

.enhancedLeadFormModalPanel {
  --enhanced-lead-questions-form-display: flex;
  --enhanced-lead-questions-form-flex: 1 1 auto;
  --enhanced-lead-questions-form-flex-direction: column;
  --enhanced-lead-questions-footer-margin: initial;
  --enhanced-lead-questions-content-wrapper-padding: 0 var(--space-6x);
  --enhanced-lead-questions-footer-padding: var(--space-4x) var(--space-6x)
    var(--space-6x);
  --enhanced-lead-form-header-padding: var(--space-5x) var(--space-6x)
    var(--space-8x);
  --enhanced-lead-form-header-margin: 0;
  --modal-padding: initial;
}
