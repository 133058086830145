.thankYouModalBasic {
  --modal-width: 100%;
  --modal-padding: 0;
  --modal-panel-padding: 0;
  --modal-panel-display: flex;
  --modal-panel-min-height: 100%;
  --modal-panel-flex-direction: column;
  --modal-height: 100vh;
  --modal-display: flex;
  --modal-flex-direction: column;
  overflow: hidden;

  @media (--screen-lg) {
    --modal-panel-width: 879px;
    --modal-panel-min-height: 679px;

    &[data-dialog-modal] {
      --modal-width: fit-content;
      --modal-panel-width: 879px;
    }
  }
}

.thankYouModal {
  composes: thankYouModalBasic;

  @media (--screen-md) {
    --modal-margin: auto;
    --modal-height: fit-content;
    --modal-width: fit-content;
    --modal-panel-height: fit-content;
    --modal-panel-width: 570px;

    &[data-dialog-modal] {
      --modal-height: unset;
      --modal-width: fit-content;
      --modal-panel-width: 570px;
    }
  }
}
